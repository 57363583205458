<template>
    <form class="reset-password__form" @submit.prevent="verifyCode">
        <span class="reset-password__form-legend">
            For your security, please enter the one time use code that we have sent to your email.
        </span>

        <div class="form__input-form">
            <label class="form__input-label">Enter Code</label>

            <div class="form__input-icon">
                <input type="password" placeholder="******" minlength="6" maxlength="6" class="form__input" v-model="codeVerification.resetCode">

                <div class="form__input-error" v-if="errors.fields.resetCode">
                    {{ errors.fields.resetCode[0] }}
                </div>
            </div>

        </div>

        <button class="button button--success button--max-width">
            <img src="@/assets/icons/vuesax/linear/shield-security.svg" class="button__icon" v-if="!isLoader" />
            <span class="button-text-loader__loader button-text-loader__loader-primary" v-else></span>

            Verify Code
        </button>
    </form>
</template>

<script setup>
import { resetPasswordServices } from '../Services/ResetPasswordServices.js'
import { ref } from 'vue'

const isLoader = ref(false)
const codeVerification = ref({ resetCode: '' })
const { verifyCodeService, errors } = resetPasswordServices()
const emits = defineEmits(['setView'])

async function verifyCode() {
    isLoader.value = true

    const response = await verifyCodeService(codeVerification.value)

    if (response.hasErrors) {
        isLoader.value = false
        return console.error(`Error in verify code service: ${response.message}`)
    }

    emits('setView', { setView: 'newPassword', userId: response.data })
}
</script>