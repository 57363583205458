<template>
    <form class="reset-password__form" @submit.prevent="verifyCode">
        <span class="reset-password__form-legend">
            Please enter your new password.
        </span>

        <div class="form__input-form">
            <label class="form__input-label">New Password</label>

            <div class="form__input-icon">
                <input type="password" placeholder="********" class="form__input" v-model="password.password">
                
                <div class="form__input-error" v-if="errors.fields.password">
                    {{ errors.fields.password[0] }}
                </div>
            </div>
        </div>

        <div class="form__input-form">
            <label class="form__input-label">Confirm Password</label>

            <div class="form__input-icon">
                <input type="password" placeholder="********" class="form__input" v-model="password.passwordConfirmation">
                
                <div class="form__input-error" v-if="errors.fields.passwordConfirmation">
                    {{ errors.fields.passwordConfirmation[0] }}
                </div>
            </div>
        </div>

        <button class="button button--success button--max-width">
            <img src="@/assets/icons/vuesax/linear/lock.svg" class="button__icon" v-if="!isLoader" />
            <span class="button-text-loader__loader button-text-loader__loader-primary" v-else></span>

            Update Password
        </button>
    </form>
</template>

<script setup>
import { resetPasswordServices } from '../Services/ResetPasswordServices.js'
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { sweetAlerts } from '@/helpers/SweetAlert.js'

const isLoader = ref(false)
const emits = defineEmits(['setView'])
const props = defineProps(['userId'])
const { resetPasswordService, errors } = resetPasswordServices()
const router = useRouter()
const { sweetSuccess } = sweetAlerts()
const password = reactive({
    password: '',
    passwordConfirmation: '',
    userId: props.userId
})

async function verifyCode() {
    isLoader.value = true

    const response = await resetPasswordService(password)

    if (response.hasErrors) {
        isLoader.value = false
        return console.error(`Error in verify code service: ${response.message}`)
    }

    router.push('/login')

    return sweetSuccess("Password updated successfully, please Sing In.")
    // emits('setView', { setView: 'finalMessage', code: null })
}
</script>